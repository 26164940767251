// 'use strict';

Handlebars.registerHelper('host', function() {
	return location.origin;
});

Handlebars.registerHelper('priority', function(priority) {
	if (priority) {
		switch (parseInt(priority, 10)) {
			case 1:
				return Utils.translate('High');
			case 2:
				return Utils.translate('Normal');
			case 3:
				return Utils.translate('Low');
			default:
				return Utils.translate(priority);
		}
	}
});

Handlebars.registerHelper('date', function(date, format) {
	if (typeof format !== 'string') {
		format = 'YYYY-MM-DD HH:mm:ss';
	}
	if (date) {
		if (typeof date === 'string') {
			if (date.search(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/) === 0) {
				date = date + 'Z';
			}
		}
		return date === 'now' ? moment().format(format) : moment(new Date(date)).format(format);
	}
	return '';
});

Handlebars.registerHelper('daysFromNow', function(date, options) {
	if (date) {
		const diff = moment(date).startOf('day').diff(moment().startOf('day'), 'days');
		if (diff >= 0) {
			return options.fn(diff);
		} else {
			return options.inverse(-diff);
		}
	} else {
		return options.inverse();
	}
});

Handlebars.registerHelper('duration', function(seconds, format, unit) {
	if (arguments.length <= 2) {
		format = 'd[d], h[h], m[min], s[s]';
	}
	if (arguments.length <= 3) {
		unit = 's';
	}
	return seconds ? moment.duration(seconds, unit).format(format) : '';
});

Handlebars.registerHelper('format', function(number, format) {
	if (isNaN(number) && typeof format === 'number') {
		return 'N/A';
	}
	if (typeof format !== 'string' && typeof format !== 'number') {
		return number;
	}
	if (typeof number === 'string' && !isNaN(number)) {
		number = parseFloat(number, 10);
	}
	if (typeof number === 'number') {
		return accounting.formatNumber(number, format);
	} else {
		return number;
	}
});

Handlebars.registerHelper('formatReport', function(i, list, labels) {
	let totalIndex = _.findIndex(labels, {name: 'total'});
	if (list) {
		let total = list[totalIndex];
		let number = list[i];
		if (typeof total !== 'undefined' && i < totalIndex && number === parseInt(number, 10) && total === parseInt(total, 10) && total && number) {
			return Math.round((number/total) * 100) + '%';
		} else 	if (typeof number === 'number') {
			return accounting.formatNumber(number);
		} else {
			return number;
		}
	}
});

Handlebars.registerHelper('translate-label', function(message) {
	return message ? Utils.translate(message) : '';
});

Handlebars.registerHelper('translate-status', function(message) {
	message = message === null ? '' : message.toString();
	// eslint-disable-next-line no-undef
	defect_status.each(status => {
		message = message.toString().replace(status.get('status'), JST['msg.hbs'](status.get('status')).trim());
	});
	return (JST['msg.hbs'](message || '')).trim();
});

Handlebars.registerHelper('translate', function(label, pattern, options) {
	let match = new RegExp('^' + pattern.replace(/([()])/g, '\\$1').replace(/{{value}}/g, '(.*)') + '$').exec(label);
	if (match) {
		return options.fn({
			value: match[1],
			match,
		});
	} else {
		return options.inverse();
	}
});

Handlebars.registerHelper('plain', function(html = '') {
	function strip(html) {
		var tmp = document.createElement('DIV');
		tmp.innerHTML = html;
		return tmp.textContent || tmp.innerText || '';
	}
	return html ? strip(_.find(html.split(/<\/?.+?>/), str => !!str.trim())): '';
});

Handlebars.registerHelper('formatMessage', function(html) {
	return new Handlebars.SafeString((html ? ((JST['msg.hbs'](html))||html).trim() : '').replace(/\n/g, '<br />'));
});

Handlebars.registerHelper('html', function(html) {
	return new Handlebars.SafeString((html||'').replace(/&quot;/g, '"'));
});

Handlebars.registerHelper('trim', function(html, opt_length) {
	var length = opt_length || 10;
	return html.substr(0, length);
});

Handlebars.registerHelper('padding', function(level) {
	var str = '';
	for (var i=0; i<level; i++) {
		str += '&nbsp;&nbsp;';
	}
	return str;
});

Handlebars.registerHelper('textFormatter', function(html) {

	function renderTables(txt) {
		if (txt) {
			let rx = /\{\{ *?(\|(?:.|\n|\r)*?\|) *?\}\}/gm;
			txt = txt.replace(rx, (match) => {
				let lines = match.split(/<br *>/);
				return `<table>${lines.map((line, i) => {
					let node = 'td';
					if (i === 0) {
						node = 'th';
					}
					return `<tr>${line.split('|').slice(1, -1).map(val => `<${node}>${val}</${node}>`).join('')}</tr>`;
				}).join('')}</table>`;
			});
		}
		return txt;
	}
	return new Handlebars.SafeString(renderTables(html||'')
		.replace(
			/<a href="(.*)"><img width="16" height="16" src="gfx\/screenshot.png"><\/a>/g,
			'<a href="$1"><img style="mac-width:500px" src="$1"></a>'
		)
	);
});

Handlebars.registerHelper('sum', function(...params) {
	params.pop(); //-- remove handlebars options parameter
	return params.reduce((sum, el) => sum + (el||0), 0);
});

Handlebars.registerHelper('equal', function(v1) {
	var options = arguments[arguments.length-1];
	for (var i = 1; i < arguments.length - 1; i++) {
		if (v1 == arguments[i]) {
			return options.fn(this);
		}
	}
	return options.inverse(this);
});

Handlebars.registerHelper('in', function(obj, arr) {
	if (typeof arr === 'string') {
		arr = arr.split(',');
	}
	if (arr && _.includes(arr, obj)) {
		return true;
	} else if (arr && typeof obj === 'string' && _.find(arr, { id: obj })) {
		return true;
	} else {
		return false;
	}
});

Handlebars.registerHelper('typeof', function(obj, type) {
	return typeof obj === type;
});

Handlebars.registerHelper('encodeURIComponent', function(str) {
	return encodeURIComponent(str);
});

Handlebars.registerHelper('match', function(str, pattern) {
	return new RegExp(pattern, 'i').test(str);
});

Handlebars.registerHelper('prop', function(object, property, defaultValue) {
	if (object && object[property]) {
		return object[property];
	} else if ((typeof defaultValue === 'string') || (typeof defaultValue === 'number')) {
		return defaultValue;
	} else {
		return null;
	}
});

Handlebars.registerHelper('parseDefaultValues', function(field, options) {
	if (typeof field.default === 'object') {
		return options.fn(field);
	}	else if (typeof field.default === 'string' && field.default.indexOf(',')>=0) {
		field.default = field.default.split(',');
		return options.fn(field);
	} else {
		return options.inverse(field);
	}
});

Handlebars.registerHelper('status_class', function() {
	switch (this.status) {
		case 'passed': return 'text-success';
		case 'failed': return 'text-danger';
		case 'blocked': return 'text-warning';
		case 'in progress': return 'text-info';
		default: return '';
	}
});

Handlebars.registerHelper('btField', function(field, type, options) {
	function parseValue(item) {
		if (item.includes(':')) {
			const [ project, value ] = item.split(':');
			return { project, value };
		} else {
			return { value: item };
		}
	}
	switch (type) {
		case 'select':
			if (typeof field.default === 'string' && field.default.includes(',') && !field.default.startsWith('+')) {
				field.values = field.default.split(',').map(parseValue);
				return options.fn(field);
			} else {
				return options.inverse(field);
			}
		case 'checkbox':
			if (typeof field.default === 'string' && field.default.includes(',')>=0 && field.default.startsWith('+')) {
				field.values = field.default.substr(1).split(',').map(parseValue);
				return options.fn(field);
			} else if (field.default === 'True' || field.default === 'False') {
				return options.fn(field);
			} else {
				return options.inverse(field);
			}
		default:
			return options.fn(field);
	}
});

Handlebars.registerHelper('btWebhookURL', function(tracker) {
	if (tracker && tracker.settings && tracker.settings.webhook) {
		return `${currentUser.userSettings.get('server')}${tracker.settings.webhook.replace('CID', currentUser.get('cid'))}`;
	} else {
		return '';
	}
});


Handlebars.registerHelper('btVerified', function(id) {
	if (id) {
		let tracker = currentUser.bugTrackers.get(id);
		return tracker && tracker.get('settings').verified;
	} else {
		return false;
	}
});

Handlebars.registerHelper('attachmentRemovable', function(file, options) {
	if (file.indexOf('file_') === 0) {
		return options.fn(this);
	} else {
		return options.inverse(this);
	}
});

Handlebars.registerHelper('select', function( value, options ){
	var $el = $('<select />').html( options.fn(this) );
	$el.children('option').each((i, el) => {
		if (value === el.value) {
			el.setAttribute('selected', 'selected');
		}
	});
	return $el.html();
});

Handlebars.registerHelper('tree-collapsed', function(options) {
	return localStorage.getItem('tree-collapsed') ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('tree-size', function() {
	let treeSize = parseInt(localStorage.getItem('tree-size'), 10);
	if (isFinite(treeSize)) {
		return treeSize;
	} else {
		return window.innerWidth >= 767 ? 1 : 0;
	}
});

Handlebars.registerHelper({
	not(a) {
		return !a;
	},
	multiply(a, b) {
		return a * b;
	},
	devide(a, b) {
		return a / b;
	},
	ceil(float) {
		return Math.ceil(float);
	},
	eq(v1, v2) {
		return v1 === v2;
	},
	ne(v1, v2) {
		return v1 !== v2;
	},
	lt(v1, v2) {
		return v1 < v2;
	},
	gt(v1, v2) {
		return v1 > v2;
	},
	lte(v1, v2) {
		return v1 <= v2;
	},
	gte(v1, v2) {
		return v1 >= v2;
	},
	and(v1, v2) {
		return v1 && v2;
	},
	or(v1, v2) {
		return v1 || v2;
	},
	ie() {
		return window.navigator.userAgent.search('(?:MSIE |Trident/)') > 0;
	},
	includes(obj, arr) {
		if (typeof arr === 'string') {
			arr = arr.split(',');
		}
		if (arr && _.includes(arr, obj)) {
			return true;
		} else if (arr && typeof obj === 'string' && _.find(arr, { id: obj })) {
			return true;
		} else {
			return false;
		}
	},
});

Handlebars.registerHelper('lookupBy', function(collection, prop, val) {
	return collection?.find((item => item[prop] === val));
});